<template>
  <div class="container">
    <van-progress
      v-if="!showBaseQuestion"
      style="margin-top: 16px"
      :pivot-text="pivotText"
      color="#00c4b3"
      track-color="#00c4b320"
      :percentage="percentage"
    />
    <div class="main">
      <chat
        :chatContent="chatContent"
        :showBack="baseIndex > 0 || !showBaseQuestion"
        @onClickBack="onClickBack"
        @onClickOption="onClickOption"
      ></chat>
    </div>
  </div>
</template>

<script>
import chat from "@/views/components/questionnaire/chat.vue";

import { getQuestions, getAssessmentResults } from "@/api";
export default {
  components: {
    chat,
  },
  data() {
    return {
      sex: null,
      marital: null,
      questionIndex: 0,
      questionList: [],
      answers: [],
      baseList: [
        {
          question: "请问，您的性别是？",
          options: [
            {
              name: "男性",
              value: "1",
            },
            {
              name: "女性",
              value: "2",
            },
          ],
        },
        {
          question: "请问，您的婚姻状况是？",
          options: [
            {
              name: "已婚",
              value: "1",
            },
            {
              name: "未婚",
              value: "2",
            },
          ],
        },
      ],
      baseIndex: 0,
      showBaseQuestion: true,
    };
  },
  watch: {
    baseIndex(a) {
      console.log(a);
    },
  },
  computed: {
    chatContent() {
      if (this.showBaseQuestion) {
        return this.baseList[this.baseIndex];
      } else {
        return this.questionList[this.questionIndex];
      }
    },
    pivotText() {
      return `${this.questionIndex + 1}/${this.questionList.length}`;
    },
    percentage() {
      return ((this.questionIndex + 1) / this.questionList.length) * 100;
    },
  },
  mounted() {
    this.$setTitle("健康评估");
  },
  methods: {
    async getQuestionList() {
      const res = await getQuestions({
        sex: this.sex,
        marital: this.marital,
      });
      if (res.success) {
        this.questionList = res.data;
        this.showBaseQuestion = false;
      }
    },
    async getResult() {
      const res = await getAssessmentResults({
        answers: this.answers,
        sex: this.sex,
        marital: this.marital,
      });
      if (res.success) {
        localStorage.setItem("questionnaireResult", JSON.stringify(res.data));

        let projects = []
        res.data.hospitalPackages.forEach((item) => {
          item.hospitalProjects.forEach((project) => {
            projects.push({ ...project, checked: true })
          });
        });
        localStorage.setItem(
          "projects",
          JSON.stringify(projects)
        );
        this.$router.replace("/questionnaire-result");
      }
    },
    onClickOption(option) {
      if (this.showBaseQuestion) {
        this.baseList[this.baseIndex].options.forEach((item) => {
          item.value === option.value
            ? (item.checked = true)
            : (item.checked = false);
        });
        if (this.baseIndex === 0) {
          this.sex = option.value;
          this.marital = null;
          if (this.sex === "1") {
            this.getQuestionList();
            return;
          }
        }
        if (this.baseIndex === 1) {
          this.marital = option.value;
        }

        if (this.baseIndex >= this.baseList.length - 1) {
          this.getQuestionList();
        } else {
          this.baseIndex++;
        }
      } else {
        this.questionList[this.questionIndex].options.forEach((item) => {
          item.value === option.value
            ? (item.checked = true)
            : (item.checked = false);
        });
        this.answers[this.questionIndex] = {
          selectedValue: option.value,
          questionId: this.questionList[this.questionIndex].id,
        };
        console.log(this.answers);
        if (this.questionIndex + 1 >= this.questionList.length) {
          this.getResult();
          return;
        }
        this.questionIndex++;
      }
    },
    onClickBack() {
      if (this.showBaseQuestion) {
        if (this.baseIndex <= 0) return;
        this.baseList[this.baseIndex].options.forEach((item) => {
          item.checked = false;
        });
        this.baseIndex--;
      } else {
        if (this.questionIndex <= 0) {
          this.showBaseQuestion = true;
          return;
        }
        this.questionList[this.questionIndex].options.forEach((item) => {
          item.checked = false;
        });
        this.questionIndex--;
      }
    },
  },
};
</script>


<style scoped>
.container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
}
.main {
  flex: 1;
  margin-top: 50px;
  padding: 0 15px 20px 15px;
  overflow-y: scroll;

}
</style>